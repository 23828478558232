import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function StaticGallery() {
  return (
    <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
      <h2 className={'text-2xl sm:text-3xl font-bold my-24 max-w-screen-lg'}>Life with Gill</h2>
        <div className={'grid gap-6'}>
          <StaticImage
            src="../images/gallery/1.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/2.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/3.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/4.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/5.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/6.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/7.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/8.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/9.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/10.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/11.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/12.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/13.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/14.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/15.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
          <StaticImage
            src="../images/gallery/16.jpg"
            alt="Gillian Morrow"
            layout="fullWidth"
          />
        </div>


    </div>
  );
}
