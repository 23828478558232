import * as React from "react"
import Layout from "../components/layout"
import Commission from "../components/Commission"
import StaticGallery from "../components/StaticGallery"
import { StaticImage } from "gatsby-plugin-image"

const AboutUsPage = () => {
  return (
    <Layout>

      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
              <h2 className={'text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold my-24 max-w-screen-lg'}>I am a Belfast based figurative artist currently focusing on busts and portraits.</h2>
              <div className={'mb-16'}>
                <StaticImage
                  src="../images/gillian-morrow.jpg"
                  alt="Gillian Morrow"
                  layout="fullWidth"
                />
              </div>

              <div className={'prose prose-lg lg:prose-2xl'}>
                <p>Exhibited in Royal Ulster Academy’s annual exhibition 2016, 2017, 2018.</p>
                <p>Finalist in Zurich Portrait Prize – exhibiting in the National Gallery 2018</p>
                <p>Exhibited in Royal Hibernian Academy’s annual exhibition 2019</p>
                <p>In my work I hope to show, not only, a physical representation but to achieve a deeper&nbsp;sense of the sitter.</p>
                <p>The busts I create are sculpted by hand in porcelain clay. As no cast is made, each one is&nbsp;unique. The wet clay is left to air-dry for a few weeks before bisquing in my kiln. Then the&nbsp;busts are ready for glazing – a process which varies according to the finish required – before&nbsp;a final firing at 1250°C, which transforms the clay into an enduring vitrified porcelain.</p>
                <p>In my commissions I work from live models, or photographs if necessary. You may prefer&nbsp;to capture a likeness of someone in your life or alternatively&nbsp;have an imaginary or fantasy&nbsp;figure to complement and enhance your decor.</p>
              </div>

      </main>

        <StaticGallery />
        <Commission />
    </Layout>
  )
}

export default AboutUsPage

export const Head = () => (
    <>
    <title>About Me - Gill Morrow Art</title>
    <meta name="description" content="Gill Morrow Art" />
    </>
)
